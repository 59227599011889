import RegularText from '@/components/RegularText';
import { Enum, PATH } from '@/constants';
import { ActionType, CriteriaType } from '@/constants/enum';
import options from '@/constants/options';
import { checkShowErrorInline, formatCreatedAt, handleToastMutation, truncateText } from '@/helpers';
import useScope from '@/hooks/Scope';
import useCountry from '@/hooks/useCountry';
import { apiCaller } from '@/redux/query';
import {
  blackListTableSelector,
  blockListSlice,
  isDeleteAllSelector,
  perPageSelector,
  settingSelector,
} from '@/redux/slice/blockList.slice';
import toastSlice from '@/redux/slice/toast.slice';
import { IResponseApi } from '@/types/api/response.api';
import {
  EmptyState,
  Icon,
  IndexTable,
  Link,
  Modal,
  Pagination,
  SkeletonBodyText,
  Text,
  Tooltip,
  useIndexResourceState,
} from '@shopify/polaris';
import { DeleteMinor, EditMinor } from '@shopify/polaris-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const TableBlackList = (): JSX.Element => {
  const navigate = useNavigate();
  const scope = useScope();
  const perPage = useSelector(perPageSelector);
  const isDeleteAll = useSelector(isDeleteAllSelector);
  const handleCountry = useCountry();
  const dispatch = useDispatch();
  const settings = useSelector(settingSelector);

  const blackListTable = useSelector(blackListTableSelector);
  const [activeRule, activeRuleStatus] = apiCaller.useActiveRuleMutation();
  const { data, isFetching, isLoading } = apiCaller.useFetchSettingListQuery({
    ...blackListTable,
    sort: blackListTable.sort.toUpperCase(),
    perPage,
  });

  const [modalDelete, setModalDelete] = useState({
    itemSelected: -1,
    isOpenModalDelete: false,
  });
  const [deleteItem, deleteItemStatus] = apiCaller.useDeleteSettingMutation();
  const [deleteAllItem, deleteAllItemStatus] = apiCaller.useDeleteAllBlackListSettingMutation();

  const resourceName = {
    singular: 'order',
    plural: 'orders',
  };
  const handleCloseModalDelete = useCallback(() => {
    setModalDelete({
      itemSelected: -1,
      isOpenModalDelete: false,
    });
  }, []);

  const handleOpenModalDelete = useCallback(
    (id: number) => () => {
      setModalDelete({
        itemSelected: id,
        isOpenModalDelete: true,
      });
    },
    [],
  );
  const toggleRule = useCallback(
    (id: number) => () => {
      activeRule({
        priority: Enum.ListType.BlackList,
        status: !data?.settingList.find((item) => item.id === id)?.isActive,
        ids: [id],
      });
      dispatch(
        toastSlice.actions.handleToast({
          isOpen: true,
          content: 'Update',
          error: false,
        }),
      );
    },
    [data, activeRule, dispatch],
  );
  const handleEdit = useCallback(
    (item: IResponseApi.ISettingItem) => () => {
      const rule = {
        ...item,
        type: item.type,
        country: item.country ? [item.country] : undefined,
        state: item.state ? [item.state] : undefined,
        city: item.city ? [item.city] : undefined,
        ispName: item.ispName ? [item.ispName] : undefined,
        ispCode: item.ispCode ? [item.ispCode] : undefined,
        deviceType: item.deviceType || '',
        osName: item.osName || '',
        browserName: item.browserName ? [item.browserName] : [],
        isActive: item.isActive === undefined ? true : item.isActive,
        specificUrl: item.specificUrl || undefined,
      };
      if (item.criteria === CriteriaType.ISP) {
        dispatch(blockListSlice.actions.handleInputIsp(item.ispName || ''));
      }

      dispatch(blockListSlice.actions.handleErrorRule([]));
      dispatch(blockListSlice.actions.handleSetting(rule));
      dispatch(blockListSlice.actions.handleSettingBackup(rule));
      navigate(PATH.BLOCK_PAGE);
    },
    [dispatch, navigate],
  );

  const items = useMemo(() => {
    return data?.settingList.map((blockItem) => {
      const renderDescription = () => {
        const data = [
          blockItem.ipAddress,
          blockItem.city,
          blockItem.state,
          blockItem.ispName,
          blockItem.browserName,
          blockItem.osName,
          blockItem.deviceType,
          blockItem.specificUrl,
          blockItem.country ? `${handleCountry.renderCountry(blockItem.country)} (${blockItem.country})` : undefined,
        ];
        const description = data.filter((item) => !!item).join(' - ');
        if (blockItem.type === Enum.ActionType.Redirect) {
          const handleOpenURL = () => {
            window.open(blockItem.linkRedirect, '_blank');
          };
          const handleOpenReferralURL = () => {
            window.open(blockItem.referUrl, '_blank');
          };
          if (blockItem.shortUrl) {
            if (blockItem.referUrl) {
              if (blockItem.shortReferUrl) {
                return (
                  <Text as="span" variant="bodyMd" breakWord>
                    {blockItem.country ? `${handleCountry.renderCountry(blockItem.country)} (${blockItem.country})` : undefined} -{' '}
                    <Link onClick={handleOpenReferralURL} removeUnderline>
                      {blockItem.shortReferUrl}
                    </Link>{' '}
                    to{' '}
                    <Link onClick={handleOpenURL} removeUnderline>
                      {blockItem.shortUrl}
                    </Link>
                  </Text>
                );
              } else {
                return (
                  <Text as="span" variant="bodyMd" breakWord>
                    {blockItem.country ? `${handleCountry.renderCountry(blockItem.country)} (${blockItem.country})` : undefined} -{' '}
                    <Link onClick={handleOpenReferralURL} removeUnderline>
                      {blockItem.referUrl}
                    </Link>{' '}
                    to{' '}
                    <Link onClick={handleOpenURL} removeUnderline>
                      {blockItem.shortUrl}
                    </Link>
                  </Text>
                );
              }
            } else {
              return (
                <Text as="span" variant="bodyMd" breakWord>
                  {description} to{' '}
                  <Link onClick={handleOpenURL} removeUnderline>
                    {blockItem.shortUrl}
                  </Link>
                </Text>
              );
            }
          } else {
            if (blockItem.referUrl) {
              if (blockItem.shortReferUrl) {
                return (
                  <Text as="span" variant="bodyMd" breakWord>
                    {blockItem.country ? `${handleCountry.renderCountry(blockItem.country)} (${blockItem.country})` : undefined} -{' '}
                    <Link removeUnderline onClick={handleOpenReferralURL}>
                      {blockItem.shortReferUrl}
                    </Link>{' '}
                    to{' '}
                    <Link onClick={handleOpenURL} removeUnderline>
                      {blockItem.linkRedirect}
                    </Link>
                  </Text>
                );
              }
              return (
                <Text as="span" variant="bodyMd" breakWord>
                  {blockItem.country ? `${handleCountry.renderCountry(blockItem.country)} (${blockItem.country})` : undefined} -{' '}
                  <Link removeUnderline onClick={handleOpenReferralURL}>
                    {blockItem.referUrl}
                  </Link>{' '}
                  to{' '}
                  <Link onClick={handleOpenURL} removeUnderline>
                    {blockItem.linkRedirect}
                  </Link>
                </Text>
              );
            }
            return (
              <Text as="span" variant="bodyMd" breakWord>
                {description} to{' '}
                <Link onClick={handleOpenURL} removeUnderline>
                  {blockItem.linkRedirect}
                </Link>
              </Text>
            );
          }
        } else if (blockItem.referUrl) {
          const handleOpenReferralURL = () => {
            window.open(blockItem.referUrl, '_blank');
          };
          if (blockItem.shortReferUrl) {
            return (
              <Text as="span" variant="bodyMd" breakWord>
                {blockItem.country ? `${handleCountry.renderCountry(blockItem.country)} (${blockItem.country})` : undefined} -{' '}
                <Link onClick={handleOpenReferralURL} removeUnderline>
                  {blockItem.shortReferUrl}
                </Link>
              </Text>
            );
          } else {
            return (
              <Text as="span" variant="bodyMd" breakWord>
                {blockItem.country ? `${handleCountry.renderCountry(blockItem.country)} (${blockItem.country})` : undefined} -{' '}
                <Link onClick={handleOpenReferralURL} removeUnderline>
                  {blockItem.referUrl}
                </Link>
              </Text>
            );
          }
        } else {
          return description;
        }
      };
      return {
        id: blockItem.id.toString(),
        blockOrRedirect: (
          <RegularText>{options.blockTypeOptions.find((item) => item.value === blockItem.type)?.label}</RegularText>
        ),
        criteria: <RegularText>{options.criteriaFilters.find((item) => item.value === blockItem.criteria)?.label}</RegularText>,
        description: <RegularText>{renderDescription()}</RegularText>,
        createdAt: <RegularText>{formatCreatedAt(blockItem.createdAt)}</RegularText>,
        lastUpdatedAt: (
          <RegularText>{blockItem.lastUpdatedAt ? formatCreatedAt(blockItem.lastUpdatedAt * 1000) : ''}</RegularText>
        ),
        note: (
          <Tooltip content={blockItem.note}>
            <Text as="p" variant="bodyMd" breakWord>
              {truncateText(blockItem.note, 30)}
            </Text>
          </Tooltip>
        ),
        action: (
          <div className={scope.isViewOnly ? 'btn-container disable' : 'btn-container'}>
            <div className="control-btn control-btn-toggle">
              <Tooltip content={blockItem.isActive ? 'Turn on' : 'Turn off'}>
                {
                  <div
                    className={`rule-toggle ${blockItem.isActive && 'active'} ${activeRuleStatus.isLoading ? 'disable' : ''}`}
                    onClick={(event: any) => {
                      event.stopPropagation();
                      toggleRule(blockItem.id)();
                    }}
                  />
                }
              </Tooltip>
            </div>
            <div className="absolute d-flex">
              <div className="control-btn edit-btn">
                <Tooltip content="Edit">
                  <button
                    onClick={(event: any) => {
                      event.stopPropagation();
                      handleEdit(blockItem)();
                    }}
                  >
                    <Icon source={EditMinor} />
                  </button>
                </Tooltip>
              </div>
              <div className="control-btn remove-btn">
                <Tooltip content="Delete">
                  <button
                    onClick={(event: any) => {
                      event.stopPropagation();
                      handleOpenModalDelete(blockItem.id)();
                    }}
                  >
                    <Icon source={DeleteMinor} />
                  </button>
                </Tooltip>
              </div>
            </div>
          </div>
        ),
      };
    });
  }, [
    activeRuleStatus.isLoading,
    data?.settingList,
    handleCountry,
    handleEdit,
    handleOpenModalDelete,
    scope.isViewOnly,
    toggleRule,
  ]);

  const { selectedResources, allResourcesSelected, handleSelectionChange, clearSelection } = useIndexResourceState(items || []);

  const handleDelete = useCallback(
    (id: number) => () => {
      deleteItem(id).then((res) => {
        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
          if (data && data.meta.totalItems % Number(blackListTable.perPage) === 1) {
            dispatch(
              blockListSlice.actions.handleBlackListTable({
                ...blackListTable,
                page: blackListTable.page - 1 || 1,
              }),
            );
          }
          clearSelection();
          handleCloseModalDelete();
        }
      });
    },
    [blackListTable, data, deleteItem, dispatch, handleCloseModalDelete, clearSelection],
  );

  const handleDeleteSelected = useCallback(async () => {
    try {
      const res = await deleteAllItem({
        ids: JSON.stringify(selectedResources),
      });
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
        if (data?.meta.itemCount === selectedResources.length) {
          dispatch(
            blockListSlice.actions.handleBlackListTable({
              ...blackListTable,
              page: blackListTable.page - 1 || 1,
            }),
          );
        }
        handleCloseModalDelete();
        clearSelection();
      }
    } catch (error) {
      console.error('Error while deleting selected resources:', error);
    }
  }, [blackListTable, clearSelection, data?.meta.itemCount, deleteAllItem, dispatch, handleCloseModalDelete, selectedResources]);

  const rowMarkup = useMemo(() => {
    return items?.map(({ id, blockOrRedirect, criteria, description, note, action, createdAt, lastUpdatedAt }, index) => (
      <IndexTable.Row id={id} key={id} position={index} selected={selectedResources.includes(id)}>
        <IndexTable.Cell>{blockOrRedirect}</IndexTable.Cell>
        <IndexTable.Cell>{criteria}</IndexTable.Cell>
        <IndexTable.Cell className="w-400">{description}</IndexTable.Cell>
        <IndexTable.Cell>{createdAt}</IndexTable.Cell>
        <IndexTable.Cell>{lastUpdatedAt}</IndexTable.Cell>
        <IndexTable.Cell className="w-600">{note}</IndexTable.Cell>
        <IndexTable.Cell>{action}</IndexTable.Cell>
      </IndexTable.Row>
    ));
  }, [items, selectedResources]);

  const handleToggleSelected = useCallback(
    (status: boolean) => async () => {
      try {
        const res = await activeRule({
          ids: selectedResources.map((item) => +item),
          priority: Enum.ListType.BlackList,
          status,
        });
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
      } catch (error) {
        console.log(error);
      }
    },
    [activeRule, dispatch, selectedResources],
  );

  const promotedBulkActions = [
    {
      content: 'Cancel',
      onAction: () => clearSelection(),
    },
    {
      content: 'Turn on',
      onAction: handleToggleSelected(true),
    },
    {
      content: 'Turn off',
      onAction: handleToggleSelected(false),
    },

    {
      content: 'Delete',
      onAction: handleOpenModalDelete(-1),
    },
  ];

  useEffect(() => {
    if (isDeleteAll) {
      clearSelection();
    }
  }, [clearSelection, isDeleteAll]);

  return (
    <div>
      <IndexTable
        emptyState={
          isLoading ? (
            <SkeletonBodyText lines={16} />
          ) : (
            <EmptyState
              heading="Take control of your website security"
              action={{
                content: 'Start with new rule',
                onAction: () => {
                  dispatch(blockListSlice.actions.handleOpenGuide(true));
                  dispatch(blockListSlice.actions.handleTabSelectedModal(0));
                  dispatch(
                    blockListSlice.actions.handleSetting({
                      ...settings,
                      type: ActionType.Block,
                    }),
                  );
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  });
                },
              }}
              secondaryAction={{
                content: 'Learn more',
                url: 'https://docs.ipblocker.io/',
                target: '_blank',
              }}
              image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
            >
              <RegularText>Set up your blacklist now to safeguard your online space from potential threats</RegularText>
            </EmptyState>
          )
        }
        promotedBulkActions={promotedBulkActions}
        resourceName={resourceName}
        itemCount={items?.length || 0}
        selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
        onSelectionChange={handleSelectionChange}
        headings={[
          { title: 'Block or redirect' },
          { title: 'Criteria' },
          { title: 'Description' },
          { title: 'Created at' },
          { title: 'Last updated' },
          { title: 'Note' },
          { title: '' },
        ]}
      >
        {isLoading ? <SkeletonBodyText lines={16} /> : rowMarkup}
      </IndexTable>
      <div className="mt-16 pb-16">
        <Pagination
          label={
            data?.meta.totalItems
              ? `Showing ${(data.meta.currentPage - 1) * Number(data.meta.perPage) + 1} to ${Math.min(
                data.meta.currentPage * Number(data.meta.perPage),
                data?.meta.totalItems,
              )} of ${data?.meta.totalItems} items`
              : null
          }
          hasPrevious={!isFetching && data && data.meta.currentPage > 1}
          onPrevious={() => {
            if (data) {
              dispatch(
                blockListSlice.actions.handleBlackListTable({
                  ...blackListTable,
                  page: data && data.meta.currentPage - 1,
                }),
              );
            }
          }}
          hasNext={!isFetching && data && data.meta.currentPage < Math.ceil(data.meta.totalItems / Number(data.meta.perPage))}
          onNext={() => {
            if (data) {
              dispatch(
                blockListSlice.actions.handleBlackListTable({
                  ...blackListTable,
                  page: data.meta.currentPage + 1,
                }),
              );
            }
          }}
        />
      </div>
      <Modal
        open={modalDelete.isOpenModalDelete}
        onClose={handleCloseModalDelete}
        title={modalDelete.itemSelected === -1 ? 'Do you want to delete all selected rules' : 'Delete rule'}
        primaryAction={{
          destructive: true,
          content: 'Delete',
          onAction: modalDelete.itemSelected === -1 ? handleDeleteSelected : handleDelete(modalDelete.itemSelected),
          loading: deleteItemStatus.isLoading || deleteAllItemStatus.isLoading,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleCloseModalDelete,
          },
        ]}
      >
        <Modal.Section>
          <RegularText>If you delete the rule, you won't be able to revert it.</RegularText>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default TableBlackList;
